
#site-footer{
    padding-top: 20px;
    min-height: 100px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;

}


.business-footer{
    color: var(--gold);
    text-align: center;
}


.contact{
    text-align: left;
    font-size: 18px;

}

.contact a{
    text-decoration: none;
    color: white;
    text-decoration: underline;
}

.highlighted-text{
    color: var(--secondary-color);
}




.contact a:hover span{
    color: #c07932 !important;
}



.contact a .highlighted-text{
    text-decoration: none;
    color: var(--secondary-color) !important;
}


.phone{
    color: var(--secondary-color) !important;
}


@media screen and (max-width: 650px) {
    #site-footer{
        flex-direction: column !important;
        height: fit-content;
        align-items: flex-start;
    }

    .footer-slogan{
        display: none;
    }

    #site-footer > * {
        margin-bottom: 20px;
    }

    .business-footer{
        text-align: left;
    }

    
}