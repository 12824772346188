.navbar{
    background-color: var(--primary-color);
    position: fixed;
    top: 0;
    opacity: 1;
    z-index: 10;
    box-shadow: 0px 0px 10px 0px var(--secondary-color);
    max-height: 100px;
    height: 100px;
    width: 100%;
}

.nav-container{
    padding: 5px;
    padding-bottom: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-shelf{
    border-top: 1px solid var(--tertiary-color);
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    max-height: 0px;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin-top: -15px;
    transition: all 0.6s ease-in-out;
    background-color: var(--primary-color);
    align-items: flex-start;
    overflow: hidden;
    padding-left: 10px;
    box-shadow: 0px 10px 10px 0px var(--tertiary-color);
}

.nav-shelf .nav-link{
    margin-bottom: 10px;
    margin-left: 0px;

}

.nav-shelf .theme-button{
    margin-bottom: 10px;
}


.nav-left{
    display: flex;
    align-items: center;
}

.logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    padding: 1px;
    border-radius: 10px;
    border: 2px solid var(--tertiary-color);
    margin-right: 10px;
}

.nav-title-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    padding-left: 15px;
}

.title-name{
    font-size: 20px;
    color: var(--text-color);
    margin: 0;
    margin-bottom: 2px;
}

.title-position{
    font-size: 15px;
    color: var(--secondary-color);
    margin: 0;
}


.logo-image{
    width: 75%;
    height: 75%;
}


.links-section{
   display: flex;
   align-items: center;
   justify-content: flex-end;
   width: 60%;
   margin-right: 50px;
}

.nav-link {
    cursor: pointer;
    display: flex !important;
    position: relative !important;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
    color: var(--text-color);
  }
  .nav-link:after {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: 0px;
    background: var(--tertiary-color);
    height: 3px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .nav-link:hover:after,
  .nav-link:focus:after,
  .nav-link:active:after {
    right: 0;
  }


.active::after{
    position: static !important;
}

.active{
    text-decoration: underline 3px var(--secondary-color) !important;
}

.theme-button{
    border: 2px solid var(--tertiary-color);
    padding: 5px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
}

.theme-image{
    height: 15px;
    width: 15px;
}

.theme-button:hover{
    cursor: pointer;
    background-color: var(--tertiary-color);
    border: 3px solid var(--secondary-color);
}


.nav-toggle{
    border: 2px solid var(--tertiary-color);
    height: 60px;
    width: 60px;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    cursor: pointer;
    overflow: hidden !important;
    
}

.nav-toggle:hover{
    background-color: var(--tertiary-color);
}

.nav-toggle-brgr{
    width: 30px;
    min-width: 22px;
    height: 3px;
    border-radius: 2px;
    background: var(--text-color);

}

.nav-toggle-brgr::before,.nav-toggle-brgr::after{
    content: '';
    background: var(--text-color);
    width: 30px;
    max-width: 30px;
    height: 3px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: all 0.3s ease-in-out;
}

.nav-toggle-brgr::before{
    transform: translateY(-10px);
}

.nav-toggle-brgr::after{
    transform: translateY(10px);
}


.nav-toggle.open .nav-toggle-brgr{
   transform: translateX(-50px);
}

.nav-toggle.open .nav-toggle-brgr::before{
    transform: rotate(45deg) translate(35px, -35px);
}

.nav-toggle.open .nav-toggle-brgr::after{
     transform: rotate(-45deg) translate(35px, 35px);

}



.responsive-links{
    display: none;
    margin-right: 20px;
}


@media screen and (min-width: 601px) {
    .nav-shelf{
        display: none;
    }
}


@media screen and (max-width: 600px) {
    .links-section{
        display: none;
    }
    .responsive-links{
        display: flex;
    }

}
