
body{
    margin: 0;
    font-family: 'Mitr', sans-serif;
    background-color: black;
    overflow-x: hidden;
    width: 100%;
}

#header{
    display: flex;
    height: fit-content;
    box-shadow: 0px 10px 10px var(--secondary-color);
    padding-top: 100px;
}

.site-logo-container{
    padding: 50px;
    overflow: hidden;
    width: 50%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.site-logo{
    width: 500px;
    height: auto;
}

.austin-headshot{
    width: 40%;
}


.services{
    color: white;
    width: 50%;
    height: 400px;
    background-color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0%;

}

.services-header{
    text-align: center;
    margin-top: 25px;
    font-size: 30px;
    white-space: nowrap;
}

.services-list{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 20px;
}

.services-list > li{
    margin-bottom: 5px;
}


#site-body{
    padding-top: 50px;
    background-color: var(--primary-color);
    width: 100%;
    height: fit-content;
}

.body-header{
    color: white;
    text-align: center;
}

#breakdown-section{
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5%;
    width: 90%;
}

#inspection-section{
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5%;
    width: 90%;
}

#welding-section{
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5%;
    width: 90%;
}


.car-breakdown-image{
    height: auto;
    width: 40%;
    border-radius: 5px;
}

.battery-dead-image{
    height: auto;
    width: 40%;
    border-radius: 5px;
}

.inspection-image{
    height: auto;
    width: 40%;
    border-radius: 5px;
}





.breakdown-text{
    width: 60%;
    text-align: left;
    color: var(--secondary-color);
    font-size: 18px;
}

#charging-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.inspection-text{
    width: 60%;
    text-align: left;
    font-size: 18px;
    color: var(--secondary-color);
}

.welding-text{
    width: 60%;
    text-align: left;
    font-size: 18px;
    color: var(--secondary-color);
}

.charging-text{
    width: 50%;
    text-align: left;
    padding: 10px;
    font-size: 18px;

    color: var(--secondary-color);

}

#disclaimer-section{
    padding-top: 50px;
    margin: 0;
    width: 90%;
    margin-left: 5%;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.disclaimer-header{
    margin: 0;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.disclaimer-text{
    color: var(--secondary-color);
    text-align: center; 
    width: 50%;
    text-align: left;
    font-size: 18px;
}





@media screen and (max-width: 700px) {
    #header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .site-logo{
        width: 320px;
    }

    .body-header{
        font-size: 15px;
    }
}


@media screen and (max-width: 600px) {
    .breakdown-text{
        width: 100%;
    }

    .inspection-text{
        width: 100%;
    }

    .welding-text{
        width: 100%;
    }

    .disclaimer-text{
        width: 100%;
    }
}
